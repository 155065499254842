import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Layout} from '../components/layout'
import {media} from '../styles/media-query-template';
import {passLanguage, useLanguage} from '../components/language-context';
import {Section} from '../components/section';
import {FormType, RequestForm} from '../components/request-form';

const HorizontalLayout = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const ContactInfo = styled.div`
  margin: 16px 0;
  width: 100%;

  ${media.medium`
    max-width: 50%;
    flex: 0 0 50%;
  `}
`;

const ContactMethod = styled.div`
  margin-bottom: 24px;
`;

const ContactUsForm = styled.div`
  width: 100%;

  ${media.medium`
    max-width: 50%;
    flex: 0 0 50%;
  `}
`;

const Bold = styled.span`
  font-weight: bold;
`;

const ForrAddress = styled.div`
  *[dir='rtl'] & {
    text-align: right;
    direction: ltr;
  }
`;

const ContactTime = styled.span`
  *[dir='rtl'] & {
    direction: ltr;
  }
`;

const GetFromPath = () => {
  const regex = /from=(.*)/i;
  const paramStr = window.location.search.substring(1);
  const result = paramStr.match(regex);
  return result === null ? "" : result[1];
};

const ContactUsPageBase = useLanguage(({location, lang, msg}) => {
  const [fromPath, setFromPath] = useState(null);
  useEffect(() => {
    setFromPath(GetFromPath());
  }, []);

  return (
  <Layout location={location}>
    <Section id='contact-us-form' title={msg('contact-us')} showLine={true} lang={lang}>
      <HorizontalLayout>
        <ContactInfo>
          <ContactMethod>
            <Bold>{msg('contactus-call-us')}</Bold><br />
            {msg('contactus-americas')}: +16176136790<br />
            {msg('contactus-asia')}: +65-6426-7049<br />
            {msg('contactus-europe')}: +41215607102<br />
          </ContactMethod>
          <ContactMethod>
            <Bold>{msg('contactus-email-us')}</Bold><br />
            info@feedbacknow.com
          </ContactMethod>
          <ContactMethod>
          <Bold>{msg('contactus-mail-us')}</Bold><br />
            <ForrAddress>
              Forrester Research, Inc.<br />
              60 Acorn Park Drive<br />
              Cambridge, MA 02140<br />
              United States
            </ForrAddress>
            {msg('contactus-mon-fri')} <ContactTime dir='ltr'>{msg('contactus-time')}</ContactTime><br />
          </ContactMethod>
        </ContactInfo>
        <ContactUsForm>
          <RequestForm formType={FormType.CONTACT_US} fromPath={fromPath} />
        </ContactUsForm>
      </HorizontalLayout>
    </Section>
  </Layout>
)}
  );

const ContactUsPage = passLanguage(ContactUsPageBase);

export default ContactUsPage;
